<script>
import { formatDate } from '/~/utils/format/date'
import { getCashbackRebate } from '/~/utils/rebates'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseLink from '/~/components/base/link/base-link.vue'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import CashbackPanel from '/~/components/cashback/cashback-panel.vue'
import CheckoutSummaryAddress from '/~/components/checkout/summary/checkout-summary-address.vue'
import CheckoutSummaryItems from '/~/components/checkout/summary/checkout-summary-items.vue'
import CheckoutSummaryPayWith from '/~/components/checkout/summary/checkout-summary-pay-with.vue'
import CheckoutConfirmationStatus from '/~/components/checkout/summary/checkout-summary-status.vue'
import CheckoutSummaryTotal from '/~/components/checkout/summary/checkout-summary-total.vue'
import PointsPanel from '/~/components/points/points-panel.vue'
import { useActivity } from '/~/composables/activity'
import { useCart } from '/~/composables/cart'
import { useCms } from '/~/composables/cms/use-cms'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { usePoints } from '/~/composables/points'
import { useProvider } from '/~/composables/provider'

export default {
  name: 'purchase-checkout-confirmation',
  components: {
    BaseLink,
    BaseButton,
    BaseLoader,
    CheckoutConfirmationStatus,
    CheckoutSummaryAddress,
    CheckoutSummaryItems,
    CheckoutSummaryPayWith,
    CheckoutSummaryTotal,
    PointsPanel,
    CashbackPanel,
  },
  props: {
    orderId: {
      type: String,
      default: '',
    },
    isError: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { getPurchaseOrderItem } = useActivity()
    const { clearCart } = useCart()
    const { isPurchaseOrderPoints, calculatePointsEarnedForPurchase } =
      usePoints()
    const { ewalletLabels } = useCms()
    const { isUserAddressEnabled } = useProvider()

    return {
      getPurchaseOrderItem,
      clearCart,
      isPurchaseOrderPoints,
      calculatePointsEarnedForPurchase,
      formatDate,
      PaymentMethodType,
      ewalletLabels,
      isUserAddressEnabled,
    }
  },

  data() {
    return {
      isReady: false,
      order: null,
      failed: false,
    }
  },
  computed: {
    fees() {
      return this.order.fees?.items ?? []
    },
    status() {
      return (this.order?.status ?? '').toLowerCase()
    },
    financialStatus() {
      return (this.order?.financialStatus ?? '').toLowerCase()
    },
    isCompleted() {
      return (
        this.financialStatus === 'paid' &&
        ['processing', 'completed'].includes(this.status)
      )
    },
    paid() {
      return this.financialStatus === 'paid'
    },
    cancelled() {
      return this.order?.cancelled ?? false
    },
    orderItems() {
      return this.order?.items ?? []
    },
    coupons() {
      const items = this.order.paymentMethods.filter(
        (i) => i.type === this.PaymentMethodType.couponCode
      )

      return {
        items,
        total: items.reduce((sum, i) => sum + Number(i.amount), 0),
      }
    },
    orderFilteredItems() {
      return this.orderItems.filter((i) => i.type !== 'coupon')
    },
    eStoreItems() {
      return this.order?.e_store_items ?? []
    },
    hasPhysical() {
      return this.orderFilteredItems.some(
        (item) => item.physical && item.type !== 'estore'
      )
    },
    hasDigital() {
      return this.orderFilteredItems.some((item) => !item.physical)
    },
    hasBankAccountMethod() {
      return Boolean(this.bankAccount)
    },
    singleDigital() {
      if (this.orderFilteredItems.length === 1) {
        const item = this.orderFilteredItems[0]

        return !item.physical ? item : false
      }

      return false
    },
    hasESore() {
      return (
        this.eStoreItems.length > 0 ||
        this.orderFilteredItems.some((item) => item.type === 'estore')
      )
    },
    hasCinema() {
      return this.orderFilteredItems.some((item) => item.type === 'voucher')
    },
    isGift() {
      return this.orderFilteredItems?.[0]?.gift && this.singleDigital
    },
    items() {
      const items = this.orderFilteredItems.map((item) => {
        if (!item.type) {
          item.type = 'retailer'
        }
        return item
      })

      const eStoreItems = this.eStoreItems.map((item) => {
        item.type = 'product'
        return item
      })

      return [...items, ...eStoreItems]
    },
    ePurchasesRoute() {
      return { hash: '#profile-e-purchases' }
    },
    transactionFee() {
      return this.fees.find((item) => item.label === 'Processing Fee') ?? {}
    },
    transactionFeeAmount() {
      return Number(this.transactionFee.amount ?? 0)
    },
    transactionFeePercentage() {
      return Number(this.transactionFee.percentageFee ?? 0)
    },
    shippingFee() {
      return Number(
        this.fees.find((item) => item.label === 'Shipping Fee')?.amount ?? 0
      )
    },
    programFees() {
      return this.fees.find((item) => item.label === 'Program Fee') ?? null
    },
    programFeesAmount() {
      return this.programFees ? this.programFees.amount : 0
    },
    totalFees() {
      return this.transactionFeeAmount + this.programFeesAmount
    },
    creditCard() {
      const card = this.order.paymentMethods.find(
        (i) => i.type === this.PaymentMethodType.creditCard
      )

      if (!card) {
        return
      }

      return {
        details: { ...card, feePercentage: this.transactionFeePercentage },
        total: Number(card.finalAmount || card.amount),
      }
    },
    points() {
      const points = this.order.paymentMethods.find(
        (i) => i.type === this.PaymentMethodType.points
      )

      if (!points) {
        return null
      }

      return {
        details: {
          usePoints: points.points,
        },
        total: Number(points.amount),
      }
    },
    ewallet() {
      const ewallet = this.order.paymentMethods.find(
        (i) => i.type === this.PaymentMethodType.eWallet
      )

      if (!ewallet) {
        return
      }

      const details = {
        // usePoints: 0,
      }

      return {
        details,
        total: Number(ewallet.amount),
      }
    },
    bankAccount() {
      const bankAccount = this.order.paymentMethods.find(
        (i) => i.type === this.PaymentMethodType.bankAccount
      )

      if (!bankAccount) {
        return
      }

      const details = {
        accountName: 'Bank Account',
        accountNumber: bankAccount.accountNumber || bankAccount.number,
        bsb: bankAccount.bsb || bankAccount.prefix,
        fee: bankAccount.fee,
        feePercentage: this.transactionFeePercentage,
      }

      return {
        details,
        total: Number(bankAccount.finalAmount ?? bankAccount.amount),
      }
    },
    pointsEarned() {
      const pointsTransactions = this.order.pointsTransactions || []
      const earnTransaction = pointsTransactions.filter(
        (t) => t.type === 'earn'
      )

      if (earnTransaction.length > 0) {
        return earnTransaction[0].allocatedValue
      }

      return this.calculatePointsEarnedForPurchase(this.order.items)
    },
    cashbackRebateTotal() {
      return getCashbackRebate(this.order.rebateTotalByType)
    },
  },
  mounted() {
    this.getPurchaseOrderItem(this.orderId)
      .then((order) => {
        this.order = order

        if (this.paid) {
          this.clearCart()
        }
      })
      .catch(() => {
        this.failed = true
      })
      .finally(() => {
        this.isReady = true
      })
  },
}
</script>

<template>
  <div class="flex min-h-full w-full">
    <div
      class="flex min-h-full w-full flex-col rounded-t-3xl bg-eonx-neutral-50 sm:mx-6 sm:pt-[30px]"
    >
      <div
        class="relative mx-auto flex h-full w-full max-w-lg flex-col bg-white sm:rounded-t-3xl sm:pt-[35px]"
      >
        <div class="bg-white px-5 pt-10 sm:px-10 sm:pt-0">
          <base-loader v-if="!isReady" fullwidth />

          <div
            v-else-if="failed"
            class="py-[30px] text-center text-2xl font-bold"
          >
            Order information not found

            <div class="mt-[30px]">
              <base-button full-width @click="$router.push({ name: 'home' })">
                Back to Dashboard
              </base-button>
            </div>
          </div>

          <div v-else>
            <checkout-confirmation-status :order="order" />

            <div class="mb-6 text-center">
              <div class="font-bold leading-relaxed text-eonx-neutral-600">
                Order No. #{{ orderId }}
              </div>
              <div
                class="mt-[5px] font-bold leading-relaxed text-eonx-neutral-600"
              >
                {{ formatDate('daymonthyearlongtime', order.placedAt) }}
              </div>
            </div>

            <div class="mb-6 space-y-6">
              <p v-if="hasDigital" class="w-full text-left text-sm leading-6">
                <template v-if="hasBankAccountMethod">
                  By selecting your bank account as the payment method, your
                  order will not be fulfilled until the funds have cleared in
                  our system.
                  <b>This can take up to 3 business days.</b>
                </template>
                <template v-else>
                  ePurchases will be delivered to your email and available in
                  {{ ewalletLabels.yourEwallet }}
                  instantly, however on occasion this may take up to 72 hours.
                </template>
              </p>

              <p
                v-if="hasPhysical"
                class="w-full text-center text-sm leading-6"
              >
                Physical Gift Cards will be mailed to your delivery address
                within 5 business days.
              </p>

              <p v-if="hasESore" class="w-full text-center text-sm leading-6">
                eStore Products will be sent to your delivery address within 5
                to 15 business days subject to stock levels. If you have any
                questions about your order,
                <base-link :to="{ name: 'help' }" class="font-bold">
                  contact us
                </base-link>
                .
              </p>
            </div>

            <div class="pb-5">
              <h2 class="mb-2.5 text-xl font-bold text-eonx-neutral-800">
                Summary
              </h2>
              <div class="divide-y">
                <checkout-summary-address
                  v-if="isUserAddressEnabled"
                  :billing="order.address"
                  :loading="!isReady"
                  class="pb-5"
                />
                <checkout-summary-items :items="items" class="py-5" />
                <checkout-summary-pay-with
                  title="Paid with"
                  class="pt-5"
                  :credit-card="creditCard"
                  :points="points"
                  :ewallet="ewallet"
                  :bank-account="bankAccount"
                  :coupons="coupons"
                />
                <checkout-summary-total
                  :transaction-fees="transactionFeeAmount"
                  :shipping-fees="shippingFee"
                  :amount="+order.subtotal"
                  :total="+order.total"
                  class="pt-5"
                />
              </div>
            </div>

            <div class="-mx-5 sm:-mx-10">
              <points-panel
                v-if="isPurchaseOrderPoints"
                :total="pointsEarned"
                :completed="isCompleted"
                :loading="!isReady"
              />
              <cashback-panel
                :total="cashbackRebateTotal"
                :completed="isCompleted"
                :loading="!isReady"
              />
            </div>

            <div class="sticky bottom-0 -mx-5 bg-white px-5 py-6 sm:-mx-10">
              <div class="flex w-full space-x-6">
                <base-button
                  v-if="paid && hasDigital && !isGift"
                  class="flex-1"
                  look="outlined-color"
                  @click="$router.push(ePurchasesRoute)"
                >
                  <template v-if="singleDigital">
                    <template v-if="hasCinema">View eVoucher</template>
                    <template v-else>View eGift Card</template>
                  </template>
                  <template v-else>View ePurchases</template>
                </base-button>

                <base-button
                  class="flex-1"
                  @click="$router.push({ name: $route.meta.home })"
                >
                  Done
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
